import React, { Component } from "react";
import { Table, Button, Modal, Form, Input, Space, Popconfirm } from "antd";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import Dragger from "antd/lib/upload/Dragger";
import {
  InboxOutlined,
  SearchOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  createSpecialPartner,
  deleteSpecialPartner,
  getSpecialPartners,
  modifySpecialPartner,
  modifySpecialPartnerIndex
} from "../../actions/business";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { MenuOutlined } from "@ant-design/icons";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSpecialPartners: () => dispatch(getSpecialPartners()),
    createSpecialPartner: (params) => dispatch(createSpecialPartner(params)),
    modifySpecialPartner: (params) => dispatch(modifySpecialPartner(params)),
    deleteSpecialPartner: (categoryId) =>
      dispatch(deleteSpecialPartner(categoryId)),
    modifySpecialPartnerIndex: (categoryId) =>
      dispatch(modifySpecialPartnerIndex(categoryId)),
  };
};

class ConnetctedSpecialPartners extends Component {
  formRef = React.createRef();

  state = {
    partners: [],
    loaded: false,
    visible: false,
    name: "",
    webPage: "",
    comment: "",
    selectedPartner: {},
    modify: false,
  };

  componentDidMount = async () => {
    await this.props.getSpecialPartners();
    this.setState({
      partners: this.props.specialPartners,
      loaded: true,
    });
  };

  handleClickNewPartner = () => {
    this.setState({ visible: true });
  };

  handleCancel = (e) => {
    e.stopPropagation();
    this.setState({
      visible: false,
      modify: false,
      selectedPartner: {},
      name: "",
      webPage: "",
      comment: "",
      file: [],
    });
    this.formRef.current.resetFields();
  };

  handleModifyPartner = (partner) => {
    console.log(partner);
    this.setState({
      visible: true,
      name: partner.name,
      webPage: partner.webpage,
      comment: partner.comment,
      selectedPartner: partner,
      modify: true,
    });
    this.formRef.current.setFieldsValue({
      name: partner.name,
      webPage: partner.webpage,
      comment: partner.comment,
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  removeFile = () => {
    this.setState({ file: [] });
  };

  beforeUpload = (file) => {
    this.setState({ file: [file] });
    return false;
  };

  handleSave = async () => {
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("webPage", this.state.webPage);
    formData.append("comment", this.state.comment);
    if (this.state.file) {
      formData.append("file", this.state.file[0]);
    }
    if (this.state.modify) {
      formData.append("id", this.state.selectedPartner.id);
      await this.props.modifySpecialPartner(formData);
    } else {
      await this.props.createSpecialPartner(formData);
    }
    if (this.props.saveStatus) {
      this.formRef.current.resetFields();
      this.setState({
        name: "",
        webPage: "",
        comment: "",
        file: [],
        modify: false,
        selectedPartner: {},
        visible: false,
      });
      await this.props.getSpecialPartners();
      this.setState({ partners: this.props.specialPartners });
    }
  };

  handleDelete = async (id) => {
    await this.props.deleteSpecialPartner(id);
    if (this.props.saveStatus) {
      await this.props.getSpecialPartners();
      this.setState({ partners: this.props.specialPartners });
    }
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getColumnSearchProps = (dataIndex, searchLabel) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Keresés: ${searchLabel}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Keresés
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            Visszaállítás
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Space>
      ) : (
        <Space align="center">{text}</Space>
      ),
  });

  onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        this.state.partners.slice(),
        oldIndex,
        newIndex
      )
        .filter((el) => !!el)
        .map((x, i) => {
          x.index = i;
          return x;
        });
      console.log("Sorted items: ", newData);
      this.setState({ partners: newData });
      const toSave = {
        partners: newData,
      };
      console.log(toSave);
      await this.props.modifySpecialPartnerIndex(toSave);
    }
  };

  render() {
    const DraggableContainer = (props) => (
      <SortableBody
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={this.onSortEnd}
        {...props}
      />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
      // function findIndex base on Table rowKey props and should always be a right array index
      const index = this.props.specialPartners.findIndex(
        (x) => x.index === restProps["data-row-key"]
      );
      return <SortableItem index={index} {...restProps} />;
    };
    const DragHandle = SortableHandle(() => (
      <MenuOutlined
        style={{
          cursor: "grab",
          color: "#999",
        }}
      />
    ));
    const columns = [
      {
        title: "Sorrend",
        dataIndex: "sort",
        width: 30,
        className: "drag-visible",
        render: () => <DragHandle />,
      },
      {
        title: "Név",
        dataIndex: "name",
        key: "name",
        ...this.getColumnSearchProps("name", "Név"),
      },
      { title: "Weblap", dataIndex: "webpage", key: "webpage" },
      { title: "Komment", dataIndex: "comment", key: "comment" },
      {
        title: "Módosítás",
        key: "modify",
        render: (text, record) => (
          <Space size="middle">
            <Button onClick={() => this.handleModifyPartner(record)}>
              Módosítás
            </Button>
          </Space>
        ),
      },
      {
        title: "Törlés",
        key: "delete",
        render: (text, record) => (
          <Space size="middle">
            <Popconfirm
              title="Biztosan törölni szeretnéd a partnert?"
              okText="Igen"
              cancelText="Nem"
              onConfirm={() => this.handleDelete(record.id)}
              okButtonProps={{ danger: true }}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <Button type="primary" danger>
                Törlés
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];
    const SortableItem = SortableElement((props) => <tr {...props} />);
    const SortableBody = SortableContainer((props) => <tbody {...props} />);
    return (
      <div>
        <h1>Kiemelt partnerek</h1>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ float: "right" }}>
            <Button type="primary" onClick={this.handleClickNewPartner}>
              Új partner
            </Button>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={this.state.partners || []}
          rowKey="index"
          locale={{ emptyText: "Nincs adat" }}
          //loading={!this.props.usersStatus && !this.state.loaded}
          pagination={{ position: ["bottomCenter"], pageSize: 10 }}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
        <Modal
          visible={this.state.visible}
          title="Kiemelt partner"
          forceRender
          onOk={this.handleSave}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Mégse
            </Button>,
            <Button
              key="submit"
              type="primary"
              //loading={loading}
              onClick={this.handleSave}
            >
              {this.state.modify ? "Módosítás" : "Mentés"}
            </Button>,
          ]}
        >
          <Form
            ref={this.formRef}
            {...formItemLayout}
            name="register"
            onFinish={this.submitForm}
            scrollToFirstError
          >
            <Form.Item
              name="name"
              label="Név"
              rules={[
                {
                  required: true,
                  message: "Kérem adja meg a partner nevét!",
                },
              ]}
            >
              <Input
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              name="webPage"
              label="Weboldal"
              rules={[
                {
                  required: true,
                  message: "Kérem adja meg a partner weboldalát!",
                },
              ]}
            >
              <Input
                name="webPage"
                value={this.state.webPage}
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              name="comment"
              label="Komment"
              rules={[
                {
                  required: true,
                  message: "Kérem adja meg a partner weboldalát!",
                },
              ]}
            >
              <Input
                name="comment"
                value={this.state.comment}
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              name="file"
              label="Kép"
              rules={[
                {
                  required: false,
                  message: "Kérem adjon meg egy fájlt!",
                },
              ]}
            >
              <Dragger
                name="file"
                multiple={false}
                onRemove={this.removeFile}
                accept=".png,.jpg,.jpeg,.gif"
                beforeUpload={this.beforeUpload}
                fileList={this.state.file}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Fájl feltöltéshez húzza be a mezőbe a fájlt vagy kattintson
                  ide
                </p>
              </Dragger>
            </Form.Item>
            <Form.Item>
              <Button hidden htmlType="submit"></Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  specialPartners: state.businessReducer.specialPartners,
  status: state.businessReducer.status,
  saveStatus: state.businessReducer.saveStatus,
});

const SpecialPartners = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnetctedSpecialPartners);

export default SpecialPartners;
