export const LOGIN_ACTIONS = {
  TOKEN_LOADING: "TOKEN_LOADING",
  TOKEN_LOADED: "TOKEN_LOADED",
  TOKEN_ERROR: "TOKEN_ERROR",
  LOGOUT: "LOGOUT",
};

export const USERS_ACTIONS = {
  USERS_LOADING: "USERS_LOADING",
  USERS_LOADED: "USERS_LOADED",
  USERS_ERROR: "USERS_ERROR",
  USER_REGISTER_START: "USER_REGISTER_START",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_ERROR: "USER_REGISTER_ERROR",
  GROUPS_LOADING: "GROUPS_LOADING",
  GROUPS_LOADED: "GROUPS_LOADED",
  GROUPS_ERROR: "GROUPS_ERROR",
};

export const BUSINESS_ACTIONS = {
  BUSINESS_LOADING: "BUSINESS_LOADING",
  BUSINESS_LOADED: "BUSINESS_LOADED",
  BUSINESS_ERROR: "BUSINESS_ERROR",
  BUSINESS_SAVE_START: "BUSINESS_SAVE_START",
  BUSINESS_SAVE_SUCCESS: "BUSINESS_SAVE_SUCCESS",
  BUSINESS_SAVE_ERROR: "BUSINESS_SAVE_ERROR",
  CATEGORIES_LOADING: "CATEGORIES_LOADING",
  CATEGORIES_LOADED: "CATEGORIES_LOADED",
  CATEGORIES_ERROR: "CATEGORIES_ERROR",
  BUSINESS_CATEGORIES_LOADING: "BUSINESS_CATEGORIES_LOADING",
  BUSINESS_CATEGORIES_LOADED: "BUSINESS_CATEGORIES_LOADED",
  BUSINESS_CATEGORIES_ERROR: "BUSINESS_CATEGORIES_ERROR",
  COMPLAINTS_LOADING: "COMPLAINTS_LOADING",
  COMPLAINTS_LOADED: "COMPLAINTS_LOADED",
  COMPLAINTS_ERROR: "COMPLAINTS_ERROR",
  SPECIAL_PARTNERS_LOADING: "SPECIAL_PARTNERS_LOADING",
  SPECIAL_PARTNERS_LOADED: "SPECIAL_PARTNERS_LOADED",
  SPECIAL_PARTNERS_ERROR: "SPECIAL_PARTNERS_ERROR",
  SPECIAL_PARTNERS_SAVE_START: "SPECIAL_PARTNERS_SAVE_START",
  SPECIAL_PARTNERS_SAVE_SUCCESS: "SPECIAL_PARTNERS_SAVE_SUCCESS",
  SPECIAL_PARTNERS_SAVE_ERROR: "SPECIAL_PARTNERS_SAVE_ERROR",
};
