import React, { Component } from "react";
import { Table, Button, Modal, Form, Input, Space } from "antd";
import { connect } from "react-redux";
import {
  getAllUsers,
  getAllGroups,
  registerUser,
  modifyUser,
  deleteUser,
} from "../../actions/users";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllUsers: (token) => dispatch(getAllUsers(token)),
    getAllGroups: (token) => dispatch(getAllGroups(token)),
    registerUser: (user, token) => dispatch(registerUser(user, token)),
    modifyUser: (user, token) => dispatch(modifyUser(user, token)),
    deleteUser: (userId) => dispatch(deleteUser(userId)),
  };
};

//const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

class ConnectedUsers extends Component {
  formRef = React.createRef();

  state = {
    users: [],
    loaded: false,
    visible: false,
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    //group: 2,
    selectedUser: {},
    modify: false,
  };

  componentDidMount = async () => {
    //await this.props.getAllGroups(this.props.token);
    await this.getUsers();
    this.setState({ loaded: true });
  };
  setDefaultValues = () => {
    this.setState({
      email: "",
      password: "",
      /* firstname: "",
      lastname: "", */
      //group: 2,
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeGroup = (event) => {
    this.setState({ group: event });
  };

  getUsers = async () => {
    this.setState({ loaded: false });
    await this.props.getAllUsers(this.props.token);
    if (this.props.usersStatus) {
      const users = this.props.users.map((user) => {
        const date = new Date(user.created * 1000);
        return {
          key: user.id,
          id: user.id,
          /* firstname: user.firstname,
          lastname: user.lastname, */
          email: user.email,
          //group: user.group.name === "admin" ? "Admin" : "Benutzer",
          created: date.toLocaleDateString(),
        };
      });
      this.setState({ users: users });
    }
    this.setState({ loaded: true });
  };

  handleClickNewUser = () => {
    console.log(this.state.visible);
    this.setState({ visible: true });
    console.log(this.state.visible);
  };

  handleCancel = (e) => {
    e.stopPropagation();
    console.log(this.state.visible);
    this.setState({ visible: false, modify: false });
    console.log(this.state.visible);
    this.formRef.current.resetFields();
  };

  handleSave = async () => {
    await this.formRef.current.validateFields();
    const errors = this.formRef.current.getFieldsError();
    if (
      !errors.filter((error) => {
        return error.errors.length !== 0;
      })[0]
    ) {
      await this.submitForm();
    }
    await this.getUsers();
  };

  submitForm = async () => {
    if (this.state.modify) {
      const user = {
        id: this.state.selectedUser.id,
        email: this.state.email,
        password: this.state.password,
        /* firstname: this.state.firstname,
        lastname: this.state.lastname,
        group: this.state.group, */
      };
      await this.props.modifyUser(user, this.props.token);
    } else {
      const user = {
        email: this.state.email,
        password: this.state.password,
        /*         firstname: this.state.firstname,
        lastname: this.state.lastname,
        group: this.state.group, */
      };
      await this.props.registerUser(user, this.props.token);
    }
    if (this.props.saveStatus) {
      this.setDefaultValues();
      this.formRef.current.resetFields();
      this.setState({ visible: false, modify: false });
    }
  };

  handleModifyUser = (user) => {
    this.setState(
      {
        selectedUser: user,
        modify: true,
        email: user.email,
        /* firstname: user.firstname,
        lastname: user.lastname,
        group: user.group === "Admin" ? 1 : 2, */
      },
      async () => {
        await this.handleClickNewUser();
        this.formRef.current.setFieldsValue({
          email: user.email,
          /* firstname: user.firstname,
          lastname: user.lastname,
          group: user.group === "Admin" ? 1 : 2, */
        });
      }
    );
  };

  handleDeleteUser = async (user) => {
    await this.props.deleteUser(user.id);
    await this.getUsers();
  };

  getColumnSearchProps = (dataIndex, searchLabel) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Keresés: ${searchLabel}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Keresés
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            Visszaállítás
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Space>
      ) : (
        <Space align="center">{text}</Space>
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const columns = [
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        ...this.getColumnSearchProps("email", "E-mail cím"),
      },
      { title: "Dátum", dataIndex: "created", key: "datum" },
      {
        title: "Módosítás",
        key: "modify",
        render: (text, record) => (
          <Space size="middle">
            <Button onClick={() => this.handleModifyUser(record)}>
              Módosítás
            </Button>
          </Space>
        ),
      },
      {
        title: "Törlés",
        key: "delete",
        render: (text, record) => (
          <Space size="middle">
            <Button disabled={this.state.users.length === 1 || record.id === this.props.user.id} onClick={() => this.handleDeleteUser(record)}>
              Törlés
            </Button>
          </Space>
        ),
      },
    ];
    return (
      <div>
        <h1>Felhasználók</h1>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ float: "right" }}>
            <Button type="primary" onClick={this.handleClickNewUser}>
              Új felhasználó
            </Button>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={this.state.users}
          locale={{ emptyText: "Nincs adat" }}
          loading={!this.props.usersStatus && !this.state.loaded}
          pagination={{ position: ["bottomCenter"], pageSize: 10 }}
        />
        <Modal        
          visible={this.state.visible}
          title="Új felhasználó"
          onOk={this.handleSave}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Mégse
            </Button>,
            <Button
              key="submit"
              type="primary"
              //loading={loading}
              onClick={this.handleSave}
            >
              {this.state.modify ? "Módosítás" : "Regisztráció"}
            </Button>,
          ]}
        >
          <Form
            ref={this.formRef}
            {...formItemLayout}
            name="register"
            onFinish={this.submitForm}
            scrollToFirstError
          >
            <Form.Item
              name="email"
              label="E-mail cím"
              rules={[
                {
                  type: "email",
                  message: "Helytelen formátum!",
                },
                {
                  required: true,
                  message: "Kérem adja meg E-mail címét!",
                },
              ]}
            >
              <Input
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="Jelszó"
              rules={[
                {
                  required: this.state.modify ? false : true,
                  message: "Kérem adja meg jelszavát!",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item>
              <Button hidden htmlType="submit"></Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
  users: state.usersReducer.users,
  groups: state.usersReducer.groups,
  usersStatus: state.usersReducer.status,
  saveStatus: state.usersReducer.saveStatus,
});

const Users = connect(mapStateToProps, mapDispatchToProps)(ConnectedUsers);
export default Users;
